import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/postList"

function ArticlesPage({ pageContext, data, location }) {
  const { tag } = pageContext;
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location}>
      <Seo
        title="Articles Page Title"
        description="Articles page description"
      />
      <main>
        <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
          <div className="py-16">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
              {tag}
            </h1>
            <p className="max-w-4xl text-lg sm:text-2xl sm:leading-10 font-medium">
              Get best UI, UX and product design links, delivered to your inbox
              every week. No spam, unsubscribe anytime.
            </p>
          </div>
          <div className="mb-16">
            <PostList posts={posts} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ArticlesPage

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
